import * as R from 'ramda';

import {
  commitMutation,
  graphql,
} from 'react-relay';

import environment from '../Environment';

const mutation = graphql`
  mutation StoreUploadedDocumentMutation($propositionId: String!, $documentType: DocumentTypeEnum!, $uploadedDocument: UploadedDocumentInput!) {
    storeUploadedDocument(propositionId: $propositionId, documentType: $documentType, uploadedDocument: $uploadedDocument) {
      ok
      error
    }
  }
`;

export default ({ propositionId, documentType }, uploadedDocument, onDone = null) => {
  const variables = {
    propositionId,
    documentType,
    uploadedDocument
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      onCompleted: () => {
        if (!R.isNil(onDone)) onDone();
      },
      onError: err => console.error(err),
    }
  );
};
