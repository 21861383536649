import * as R from 'ramda';

import IsMedicalCheckRequiredMutation from '../graphql/mutations/IsMedicalCheckRequiredMutation';
import DeclarativeAnnexeOrOutstandingCheckMutation from '../graphql/mutations/DeclarativeAnnexeOrOutstandingCheckMutation';
import DocumentsConvertionMutation from '../graphql/mutations/DocumentsConvertionMutation';
import DocumentsMutation from '../graphql/mutations/DocumentsMutation';
import StoreUploadedDocumentMutation from '../graphql/mutations/StoreUploadedDocumentMutation';
import ClosePropositionMutation from '../graphql/mutations/ClosePropositionMutation';
import ResumePropositionMutation from '../graphql/mutations/ResumePropositionMutation';
import SavePropositionMutation from '../graphql/mutations/SavePropositionMutation';
import SendEmailMutation from '../graphql/mutations/SendEmailMutation';
import SendForSignatureMutation from '../graphql/mutations/SendForSignatureMutation';
import SendForSignatureStatusMutation from '../graphql/mutations/SendForSignatureStatusMutation';
import MakeDuplicataMutation from '../graphql/mutations/MakeDuplicataMutation';
import MakeDuplicataStatusMutation from '../graphql/mutations/MakeDuplicataStatusMutation';
import GenerateContractNumbersMutation from '../graphql/mutations/GenerateContractNumbersMutation';
import { formatters } from '@fasstech/spid-front';
import { uploadDocument } from '../graphql/Environment';
import { createActions } from 'redux-actions';
import getAvailableOfferTypes from '../graphql/libs/getAvailableOfferTypes';
import getLegalPersons from '../graphql/libs/getLegalPersons';
import CloseAndDuplicateContractMutation from '../graphql/mutations/CloseAndDuplicateContractMutation';
import getProposition from '../graphql/libs/getProposition';
import getAmendmentInfos from '../lib/getAmendmentInfos';

const { formatContractDescription } = formatters;

const getPreviousStep = step => R.prop(step)({
  ABOUT_YOU_STEP: () => 'LOCATION_AND_DATE_STEP',
  LOCATION_AND_DATE_STEP: () => 'COMPANY_SIRET_STEP',
  EMPLOYEES_STEP: () => 'ABOUT_YOU_STEP',
  MEDICAL_CHECK_STEP: () => 'CONTRACT_DESCRIPTION_STEP',
  COMPANY_SIRET_STEP: () => 'SELECT_CCN_STEP',
  CONTRACT_NUMBERS_STEP: () => 'CONTRACT_DESCRIPTION_STEP',
  CONTRACT_DESCRIPTION_STEP: () => 'EMPLOYEES_STEP',
  BACKGROUND_STEP: () => 'CONTRACT_DESCRIPTION_STEP',
  DOCUMENTS_STEP: () => 'ABOUT_YOU_STEP',
  OUTSTANDING_CHECK_STEP: () => 'BACKGROUND_STEP',
  RELATED_CONTRACT_STEP: () => 'CONTRACT_DESCRIPTION_STEP',
  RELATED_CONTRACT_HEALTH_STEP: () => 'CONTRACT_DESCRIPTION_STEP',
  SELECT_OFFERS_STEP: () => 'ABOUT_YOU_STEP'
});

const getOfferTypeData = (contract, offerType) => offerType === 'PREVOYANCE' ? contract : contract.health;

const getNextStep = (dispatch, currentStep, data) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve) => {
    const setPropositionId = propositionId => dispatch(propositionSetPropositionId(propositionId));
    const setMedicalCheck = (v) => dispatch(propositionSetMedicalCheck(v));
    const setContractNumbers = v => dispatch(propositionSetContractNumbers(v));
    const setCcnVersion = v => dispatch(propositionSetCcnVersion(v));
    const setHealthCcnVersion = v => dispatch(propositionSetHealthCcnVersion(v));
    const setCurrentOfferType = v => dispatch(propositionSetCurrentOfferType(v));
    const setAdditionalInformations = (additionalInformations) => dispatch(propositionSetAdditionalInformations(additionalInformations));
    const setOutstandingAnnexe = v => dispatch(propositionSetOutstandingAnnexe(v));
    const setDeclarativeAnnexe = v => dispatch(propositionSetDeclarativeAnnexe(v));

    const ccnHasBothOffers = R.pathOr(false, ['ccn', 'ccnHasBothOffers'], data);
    const currentOfferType = R.propOr('PREVOYANCE', 'currentOfferType')(data);

    if (currentStep === 'SELECT_CCN_STEP') {
      return resolve('COMPANY_SIRET_STEP');
    } else if (currentStep === 'COMPANY_SIRET_STEP') {
      const { duplicata, userRole, siretInfo, extraEstablishments = [] } = data;

      getLegalPersons(
        { duplicata, userRole, siretInfo, extraEstablishments },
        (siretInfo, extraEstablishments) => {
          if (R.isNil(siretInfo)) {
            dispatch(propositionSetLegalPersonsHasError(true));
          } else {
            dispatch(propositionSetLegalPersonsHasError(false));

            dispatch(propositionSetCompanySiretInfo(siretInfo));
            dispatch(propositionSetExtraEstablishments(extraEstablishments));

            return resolve('LOCATION_AND_DATE_STEP');
          }
        });

    } else if (currentStep === 'LOCATION_AND_DATE_STEP') {
      return resolve('ABOUT_YOU_STEP');
    } else if (currentStep === 'EMPLOYEES_STEP') {
      return resolve('CONTRACT_DESCRIPTION_STEP');
    } else if (currentStep === 'CONTRACT_DESCRIPTION_STEP') {
      let {
        propositionId,
        ccn,
        brokerage,
        contractDescription,
        staff,
        postCode,
        background,
        studyAtIndem,
        duplicata,
        relatedContract,
        amendment,
        contractNumbers,
        health
      } = data;

      const offerTypeData = getOfferTypeData(data, currentOfferType);

      IsMedicalCheckRequiredMutation({
        ccnId: ccn.id,
        offerType: currentOfferType,
        ccnVersion: offerTypeData.ccnVersion,
        brokerage,
        propositionId,
        contractDescription: formatContractDescription(offerTypeData.contractDescription),
        amendment: {
          ...R.propOr({}, 'amendment', offerTypeData),
          formerContractDescription: formatContractDescription(R.pathOr({}, ['amendment', 'formerContractDescription'])(offerTypeData))
        },
        staff: offerTypeData.staff,
        postCode,
        duplicata,
        tariffStructure: R.path(['health', 'tariffStructure'], data)
      }, (ok, error, medicalCheck) => {
        if (error) {
          return resolve(`ERROR_STEP|${error}`);
        }

        const allowMedicalCheck = !duplicata && !R.propOr(false, 'checked', relatedContract);

        let valuesToSave = {
          ccnId: ccn.id,
          background,
          studyAtIndem,
          postCode
        };

        if (currentOfferType === 'PREVOYANCE') {
          valuesToSave = R.compose(
            R.assoc('staff', staff),
            R.assoc('contractDescription', formatContractDescription(contractDescription)),
            R.assoc('medicalCheck', allowMedicalCheck ? medicalCheck : false),
            R.assoc('contractNumbers', contractNumbers),
            R.assoc('amendment', {
              ...amendment,
              formerContractDescription: formatContractDescription(R.propOr({}, 'formerContractDescription')(amendment))
            })
          )(valuesToSave);
        }

        if (currentOfferType === 'SANTE') {
          valuesToSave = R.assoc(
            'health',
            R.compose(
              R.dissoc('turnovers'),
              R.assoc('contractDescription', formatContractDescription(health.contractDescription)),
              R.assoc('medicalCheck', allowMedicalCheck ? medicalCheck : false),
              R.assoc('contractNumbers', R.prop('contractNumbers', health)),
              R.assocPath(['amendment', 'formerContractDescription'], formatContractDescription(R.propOr({}, 'formerContractDescription')(amendment))),
              R.defaultTo({})
            )(health)
          )(valuesToSave);
        }

        SavePropositionMutation(
          propositionId,
          valuesToSave,
          (ok, error, propositionId, contractNumbers) => {
            if (allowMedicalCheck) {
              setMedicalCheck(medicalCheck);
            }

            if (!R.either(R.isNil, R.isEmpty)(contractNumbers)) {
              setContractNumbers(contractNumbers);
            }

            if (error) {
              return resolve(`ERROR_STEP|${error}`);
            } else if (duplicata) {
              return resolve('DOCUMENTS_STEP');
            } else if (R.pathOr(false, ['checked'], relatedContract)) {
              return resolve(currentOfferType === 'SANTE' ? 'RELATED_CONTRACT_HEALTH_STEP' : 'RELATED_CONTRACT_STEP');
            } else {
              setPropositionId(propositionId);

              getProposition(propositionId, ({ additionalInformations }) => {
                setAdditionalInformations(additionalInformations);

                if (currentOfferType === 'SANTE') {
                  return resolve('DOCUMENTS_STEP');
                }

                return resolve('BACKGROUND_STEP');
              });
            }
          }
        );
      });
    } else if (currentStep === 'RELATED_CONTRACT_STEP' || currentStep === 'RELATED_CONTRACT_HEALTH_STEP') {
      return resolve('DOCUMENTS_STEP');
    } else if (currentStep === 'BACKGROUND_STEP') {

      let { propositionId, background, studyAtIndem, medicalCheck } = data;
      SavePropositionMutation(propositionId, { background, studyAtIndem }, () => {
        DeclarativeAnnexeOrOutstandingCheckMutation(propositionId, (ok, error, { outstandingAnnexe, declarativeAnnexe }) => {
          if (error) return resolve(`ERROR_STEP|${error}`);

          const hasLessThan20Employees = R.pathOr(false, ['additionalInformations', 'PREVOYANCE', 'hasLessThan20Employees'], data);

          setOutstandingAnnexe(outstandingAnnexe);
          setDeclarativeAnnexe(declarativeAnnexe);

          if (outstandingAnnexe === 'with' && !hasLessThan20Employees)
            return resolve('OUTSTANDING_CHECK_STEP');
          else if (medicalCheck)
            return resolve('MEDICAL_CHECK_STEP');
          else
            return resolve('DOCUMENTS_STEP');

        });
      });

    } else if (currentStep === 'CONTRACT_NUMBERS_STEP') {
      const offerTypes = R.propOr([], 'offerTypes')(data);

      const contractHasBothOffers = R.both(
        R.includes('SANTE'),
        R.includes('PREVOYANCE')
      )(offerTypes);

      if (currentOfferType === 'SANTE' && contractHasBothOffers) {
        setCurrentOfferType('PREVOYANCE');
        return resolve('EMPLOYEES_STEP');
      }

      return resolve('CONTRACT_NUMBERS_STEP');
    } else if (currentStep === 'ABOUT_YOU_STEP') {
      let {
        propositionId,
        brokerage,
        ccn,
        companyInformation,
        postCode,
        startDate,
        adhesionDate,
        siret,
        siren,
        siretInfo,
        duplicata,
        extraEstablishments,
        relatedContract,
        amendment,
        staff,
        contractDescription,
        contractNumbers,
        health
      } = data;

      let propsToSave = {
        ccnId: ccn.id,
        brokerage,
        companyInformation,
        postCode,
        startDate,
        adhesionDate,
        siret,
        siren,
        siretInfo,
        extraEstablishments,
        duplicata,
        relatedContract
      };

      const {
        isProtectionAmendment,
        isHealthAmendment,
        courseIsAmendment
      } = getAmendmentInfos(data);

      if (isProtectionAmendment) {
        propsToSave = {
          ...propsToSave,
          amendment: R.assoc('formerContractDescription', formatContractDescription(R.propOr({}, 'formerContractDescription', amendment)), amendment),
          staff,
          contractDescription: formatContractDescription(contractDescription),
          contractNumbers
        };
      } else if (isHealthAmendment) {
        propsToSave = R.assoc(
          'health',
          R.compose(
            R.modifyPath(['amendment', 'formerContractDescription'], formatContractDescription),
            R.modify('contractDescription', formatContractDescription),
            R.defaultTo({})
          )(health)
        )(propsToSave);
      }

      if (!ccnHasBothOffers) {
        propsToSave = R.assoc('offerTypes', R.prop('offerTypes', ccn))(propsToSave);
      }

      const isDuplicata = R.propEq('duplicata', true, data);

      let enableMultiOffers = !isDuplicata && !courseIsAmendment;

      if (!enableMultiOffers) {
        propsToSave = R.assoc('offerTypes', [isHealthAmendment ? 'SANTE' : 'PREVOYANCE'])(propsToSave);
      }

      getAvailableOfferTypes({ ccnId: ccn.id, siret, contractId: propositionId, isProtectionAmendment, isHealthAmendment, duplicata }, availableOfferTypes => {
        if (R.length(availableOfferTypes) === 1) {
          propsToSave = R.assoc('offerTypes', availableOfferTypes)(propsToSave);
          enableMultiOffers = false;
        }

        SavePropositionMutation(
          propositionId,
          propsToSave,
          (ok, error, propositionId, contractNumbers, ccnVersion, healthCcnVersion) => {
            if (error) {
              return resolve(`ERROR_STEP|${error}`);
            }

            if (!R.isNil(ccnVersion)) {
              setCcnVersion(ccnVersion);
            }

            if (!R.isNil(healthCcnVersion)) {
              setHealthCcnVersion(healthCcnVersion);
            }

            if (R.length(availableOfferTypes) === 1) {
              setCurrentOfferType(availableOfferTypes[0]);
            }

            setPropositionId(propositionId);

            if (ccnHasBothOffers && enableMultiOffers) {
              return resolve('SELECT_OFFERS_STEP');
            }

            return resolve('EMPLOYEES_STEP');
          }
        );
      });
    } else if (currentStep === 'MEDICAL_CHECK_STEP') {
      let { userRole } = data;
      if (userRole === 'groupe_adhesion') return resolve('DOCUMENTS_STEP');
      return resolve('BACKGROUND_STEP');
    } else if (currentStep === 'DOCUMENTS_STEP') {
      return resolve ('CONTRACT_NUMBERS_STEP');
    } else if (currentStep === 'PENDING_PROPOSITION_STEP') {
      const uploadFile = (propositionId, documentType, file) => {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
          const uploadedFile = await uploadDocument(file);
          if (R.isNil(uploadedFile)) return reject();
          StoreUploadedDocumentMutation({ propositionId, documentType }, uploadedFile, () => {
            return resolve();
          });
        });
      };

      const {
        propositionId,
        overPricedRate,
        decision: {
          resumeProposition,
          propositionIsAccepted,
          propositionIsNotAccepted,
          propositionIsOverPriced,
          medicalCheckResponseIsKo,
          medicalFeedbackFile,
          outstandingAnnexeFile,
          declarativeAnnexeFile
        }
      } = data;

      if (resumeProposition) {
        ResumePropositionMutation(propositionId, () => {
          dispatch(propositionResume());
          return resolve('CONTRACT_DESCRIPTION_STEP');
        });
      } else if (propositionIsNotAccepted) {
        ClosePropositionMutation({ propositionId, reason: 'REFUSED_BY_CUSTOMER' }, () => {
          return resolve('PROPOSITION_IS_CLOSED_STEP');
        });
      } else if (propositionIsAccepted && medicalCheckResponseIsKo) {
        ClosePropositionMutation({ propositionId, reason: 'MEDICAL_CHECK_KO' }, () => {
          return resolve('PROPOSITION_IS_CLOSED_STEP');
        });
      } else {
        try {
          if (!R.isNil(medicalFeedbackFile)) {
            await uploadFile(propositionId, 'medicalFeedback', medicalFeedbackFile);
          }
          if (!R.isNil(declarativeAnnexeFile)) {
            await uploadFile(propositionId, 'declarativeAnnexe', declarativeAnnexeFile);
          }
          if (!R.isNil(outstandingAnnexeFile)) {
            await uploadFile(propositionId, 'outstandingAnnexe', outstandingAnnexeFile);
          }
          if (propositionIsOverPriced) {
            SavePropositionMutation(propositionId, { overPricedRate }, (ok, error) => {
              if (!ok) console.log(error);
              else return resolve('DOCUMENTS_STEP');
            });
          }
          else return resolve('DOCUMENTS_STEP');
        } catch (error) {
          return resolve(`ERROR_STEP|${error}`);
        }
      }
    } else if (currentStep === 'SELECT_OFFERS_STEP') {
      const {
        propositionId,
        offerTypes
      } = data;

      SavePropositionMutation(propositionId, {
        offerTypes
      }, (ok, error) => {
        if (ok && !error) {
          setCurrentOfferType(offerTypes[0]);
          return resolve('EMPLOYEES_STEP');
        }
      });
    }
  });
};

export const {
  propositionReset,
  propositionSetBackground,
  propositionSetBackgroundHasFormerContract,
  propositionSetBackgroundAtmtt,
  propositionSetContractNumbers,
  propositionSetDecision,
  propositionSetStudyAtIndem,
  propositionContractNumbersReset,
  propositionSetFetchingDocuments,
  propositionFetchingCounterInc,
  propositionFetchingReset,
  propositionFetchingSendEmailStatusSet,
  propositionFetchingSendEmailReset,
  propositionSetDuplicata,
  propositionSetLocation,
  propositionSetStartDate,
  propositionSetAdhesionDate,
  propositionSetBrokerage,
  propositionSetStaff,
  propositionSetContractDescription,
  propositionSetContractNumbersAreEdited,
  propositionSetCurrentStep,
  propositionSetCompanySiret,
  propositionSetCompanySiren,
  propositionSetCompanySiretInfo,
  propositionSetExtraEstablishments,
  propositionSetCompanyInformation,
  propositionSetCcn,
  propositionSetDocuments,
  propositionSetPropositionId,
  propositionResume,
  propositionSetMakeDuplicataStatus,
  propositionResetMakeDuplicataStatus,
  propositionSetMedicalCheck,
  propositionSetDeclarativeAnnexe,
  propositionSetOutstandingAnnexe,
  propositionSetSendForSignatureStatus,
  propositionResetSendForSignatureStatus,
  propositionSetSignature,
  propositionSetStatus,
  propositionSetUserRole,
  propositionSetOverPricedRate,
  propositionSetCcnVersion,
  propositionSetRelatedContract,
  propositionSetAmendment,
  propositionSetFixedApe,
  propositionSetOriginContractId,
  propositionSetGenerateContractNumbersError,
  propositionSetGenerateContractNumbersLoading,
  propositionSetOfferTypes,
  propositionSetCurrentOfferType,
  propositionSetHealth,
  propositionSetHealthContractNumbers,
  propositionSetHealthStaff,
  propositionSetHealthContractDescription,
  propositionSetTariffStructure,
  propositionSetTurnovers,
  propositionSetTurnover,
  propositionSetHealthTurnover,
  propositionSetLegalPersonsHasError,
  propositionSetHealthCcnVersion,
  propositionSetAdditionalInformations
} = createActions({
  PROPOSITION_RESET: () => ({}),
  PROPOSITION_CONTRACT_NUMBERS_RESET: () => ({}),
  PROPOSITION_SET_FETCHING_DOCUMENTS: (status, error) => ({ status, error }),
  PROPOSITION_FETCHING_COUNTER_INC: () => ({}),
  PROPOSITION_FETCHING_RESET: () => ({}),
  PROPOSITION_FETCHING_SEND_EMAIL_STATUS_SET: (status, error) => ({ status, error }),
  PROPOSITION_FETCHING_SEND_EMAIL_RESET: () => ({ }),
  PROPOSITION_SET_DUPLICATA: duplicata => ({ duplicata }),
  PROPOSITION_SET_BACKGROUND: (value) => ({ value }),
  PROPOSITION_SET_BACKGROUND_HAS_FORMER_CONTRACT: (value) => ({ value }),
  PROPOSITION_SET_BACKGROUND_ATMTT: (value) => ({ value }),
  PROPOSITION_SET_STUDY_AT_INDEM: (value) => ({ value }),
  PROPOSITION_SET_BROKERAGE: (brokerage) => ({ brokerage }),
  PROPOSITION_SET_STAFF: (staff) => ({ staff }),
  PROPOSITION_SET_CURRENT_STEP: (step) => ({ step }),
  PROPOSITION_SET_CCN: (ccn) => ({ ccn }),
  PROPOSITION_SET_COMPANY_SIRET: siret => ({ siret }),
  PROPOSITION_SET_COMPANY_SIREN: siren => ({ siren }),
  PROPOSITION_SET_COMPANY_SIRET_INFO: siretInfo => ({ siretInfo }),
  PROPOSITION_SET_EXTRA_ESTABLISHMENTS: extraEstablishments => ({ extraEstablishments }),
  PROPOSITION_SET_COMPANY_INFORMATION: value => ({ value }),
  PROPOSITION_SET_CONTRACT_DESCRIPTION: (contractDescription) => ({ contractDescription }),
  PROPOSITION_SET_CONTRACT_NUMBERS: (contractNumbers) => ({ contractNumbers }),
  PROPOSITION_SET_CONTRACT_NUMBERS_ARE_EDITED: (contractNumbersAreEdited) => ({ contractNumbersAreEdited }),
  PROPOSITION_SET_DOCUMENTS: documents => ({ documents }),
  PROPOSITION_SET_LOCATION: (postCode) => ({ postCode }),
  PROPOSITION_SET_MAKE_DUPLICATA_STATUS: value => ({ value }),
  PROPOSITION_RESET_MAKE_DUPLICATA_STATUS: () => ({}),
  PROPOSITION_SET_START_DATE: (startDate) => ({ startDate }),
  PROPOSITION_SET_ADHESION_DATE: (adhesionDate) => ({ adhesionDate }),
  PROPOSITION_SET_PROPOSITION_ID: (value) => ({ value }),
  PROPOSITION_SET_DECLARATIVE_ANNEXE: (value) => ({ value }),
  PROPOSITION_SET_OUTSTANDING_ANNEXE: (value) => ({ value }),
  PROPOSITION_SET_MEDICAL_CHECK: value => ({ value }),
  PROPOSITION_SET_DECISION: value => ({ value }),
  PROPOSITION_SET_SEND_FOR_SIGNATURE_STATUS: value => ({ value }),
  PROPOSITION_RESET_SEND_FOR_SIGNATURE_STATUS: () => ({}),
  PROPOSITION_SET_SIGNATURE: signature => ({ signature }),
  PROPOSITION_SET_STATUS: value => ({ value }),
  PROPOSITION_RESUME: () => ({}),
  PROPOSITION_SET_USER_ROLE: (value) => ({ value }),
  PROPOSITION_SET_OVER_PRICED_RATE: (overPricedRate) => ({ overPricedRate }),
  PROPOSITION_SET_CCN_VERSION: (ccnVersion) => ({ ccnVersion }),
  PROPOSITION_SET_RELATED_CONTRACT: (relatedContract) => ({ relatedContract }),
  PROPOSITION_SET_AMENDMENT: (amendment) => ({ amendment }),
  PROPOSITION_SET_FIXED_APE: fixedAPE => ({ fixedAPE }),
  PROPOSITION_SET_ORIGIN_CONTRACT_ID: originContractId => ({ originContractId }),
  PROPOSITION_SET_GENERATE_CONTRACT_NUMBERS_ERROR: (value) => ({ value }),
  PROPOSITION_SET_GENERATE_CONTRACT_NUMBERS_LOADING: (value) => ({ value }),
  PROPOSITION_SET_OFFER_TYPES: offerTypes => ({ offerTypes }),
  PROPOSITION_SET_CURRENT_OFFER_TYPE: offerType => ({ offerType }),
  PROPOSITION_SET_HEALTH: health => ({ health }),
  PROPOSITION_SET_HEALTH_CONTRACT_NUMBERS: contractNumbers => ({ contractNumbers }),
  PROPOSITION_SET_HEALTH_STAFF: staff => ({ staff }),
  PROPOSITION_SET_HEALTH_CONTRACT_DESCRIPTION: contractDescription => ({ contractDescription }),
  PROPOSITION_SET_TARIFF_STRUCTURE: tariffStructure => ({ tariffStructure }),
  PROPOSITION_SET_TURNOVERS: (turnovers) => ({ turnovers }),
  PROPOSITION_SET_TURNOVER: (value, contractNumberIndex, college) => ({ value, contractNumberIndex, college }),
  PROPOSITION_SET_HEALTH_TURNOVER: (value, contractNumberIndex) => ({ value, contractNumberIndex }),
  PROPOSITION_SET_LEGAL_PERSONS_HAS_ERROR: value => ({ value }),
  PROPOSITION_SET_HEALTH_CCN_VERSION: (healthCcnVersion) => ({ healthCcnVersion }),
  PROPOSITION_SET_ADDITIONAL_INFORMATIONS: additionalInformations => ({ additionalInformations })
});

export const propositionGoNextStep = () => {
  return async (dispatch, getState) => {
    const { currentStep, data, userRole } = getState().proposition;
    data.userRole = userRole;
    return dispatch(propositionSetCurrentStep(await getNextStep(dispatch, currentStep, data)));
  };
};

export const propositionGoPreviousStep = () => {
  return async (dispatch, getState) => {
    const { proposition } = getState();
    const previousStep = getPreviousStep(proposition.currentStep)();
    dispatch(propositionSetCurrentStep(previousStep));
  };
};

export const propositionDocumentsGet = () => {
  return async (dispatch, getState) => {
    const state = getState();
    let { propositionId } = state.proposition.data;

    const currentOfferType = R.pathOr('PREVOYANCE', ['proposition', 'data', 'currentOfferType'])(state);

    dispatch(propositionFetchingReset());
    dispatch(propositionSetFetchingDocuments('converting_documents', false));

    DocumentsConvertionMutation({ propositionId, offerType: currentOfferType }, (ok, token) => {
      if (ok) {
        const retrieveDocuments = (token) => {
          setTimeout(() => {
            DocumentsMutation(token, (ok, token, status, documents) => {
              if (ok === false) {
                dispatch(propositionSetFetchingDocuments('conversion_failed', true ));
              } else if (ok && R.includes(status, ['converting_documents'])) {
                dispatch(propositionFetchingCounterInc());
                retrieveDocuments(token);
              } else if (ok && status === 'documents_converted') {
                dispatch(propositionSetDocuments(documents));
                dispatch(propositionSetFetchingDocuments(status, false ));
              } else if (status === 'conversion_failed')
                dispatch(propositionSetFetchingDocuments(status, false));
            });
          }, 2000);
        };

        retrieveDocuments(token);
      }
    });
  };
};

export const propositionSendEmail = (emailId) => {
  return async (dispatch, getState) => {
    let { propositionId } = getState().proposition.data;
    dispatch(propositionFetchingSendEmailReset());
    dispatch(propositionFetchingSendEmailStatusSet(true, null));
    SendEmailMutation({ propositionId, emailId }, (ok, err) => {
      dispatch(propositionFetchingSendEmailStatusSet(false, err));
    });
  };
};

export const propositionSendForSignature = (type, mode) => {
  return async (dispatch, getState) => {
    const data = getState().proposition.data;
    let { propositionId, turnovers, health } = data;

    dispatch(propositionSetSendForSignatureStatus({ error: false, value: 'started' }));

    const formatTurnovers = R.modify(
      'turnovers',
      R.compose(
        R.map(
          R.compose(
            R.modify(
              'value',
              R.when(R.is(String), parseFloat)
            ),
            R.defaultTo({})
          )
        ),
        R.defaultTo([])
      )
    );

    const values = formatTurnovers({
      turnovers,
      health: R.compose(
        formatTurnovers,
        R.modify('contractDescription', formatContractDescription)
      )(health)
    });

    SavePropositionMutation(
      propositionId,
      values,
      (ok, error) => {
        if (ok && !error) {
          SendForSignatureMutation({ propositionId, type, mode }, (ok, token, error) => {
            if (ok) {
              const getStatus = (token) => {
                setTimeout(() => {
                  SendForSignatureStatusMutation({ propositionId, token }, (ok, status) => {
                    const currentStatus = R.path(['proposition', 'sendForSignatureStatus', 'value'], getState());

                    if (R.isNil(currentStatus)) { // Lorsqu'on quitte un parcours en cours d'envoi en signature, on réinitialise le statut d'envoi. Dans ce cas, il faut arrêter le fetch afin d'éviter d'avoir un statut email_sent_to_user, ce qui bloquerait l'envoi en fin de parcours.
                      return;
                    }

                    dispatch(propositionSetSendForSignatureStatus(status));
                    if (ok && !status.error && status.value !== 'email_sent_to_user' && status.value !== 'conversion_failed') {
                      getStatus(token);
                    }
                  });
                }, 1000);
              };

              getStatus(token);
            } else {
              dispatch(propositionSetSendForSignatureStatus({ value: null, error: `${error}` }));
            }
          });
        } else {
          dispatch(propositionSetSendForSignatureStatus({ value: null, error }));
        }
      }
    );

  };
};

export const propositionMakeDuplicata = () => {
  return async (dispatch, getState) => {
    const { propositionId } = getState().proposition.data;
    dispatch(propositionSetMakeDuplicataStatus({ value: null, error: false }));
    MakeDuplicataMutation({ propositionId }, (ok, token) => {
      if (ok) {
        const getStatus = (token) => {
          setTimeout(() => {
            MakeDuplicataStatusMutation({ propositionId, token }, (ok, status) => {
              dispatch(propositionSetMakeDuplicataStatus(status));
              if (ok && !status.error && status.value !== 'email_sent_to_user' && status.value !== 'conversion_failed') {
                getStatus(token);
              }
            });
          }, 500);
        };
        getStatus(token);
      }
    });
  };
};

export const propositionSaveContractNumbers = (contractNumbers, offerType) => {
  return async (dispatch, getState) => {
    let { propositionId, health } = getState().proposition.data;

    const saveContractNumbers = contractNumbers => {
      if (offerType === 'SANTE') {
        dispatch(propositionSetHealthContractNumbers(contractNumbers));
      } else {
        dispatch(propositionSetContractNumbers(contractNumbers));
      }
    };

    const values = offerType === 'PREVOYANCE' ? { contractNumbers } : R.compose(
      R.dissocPath(['health', 'turnovers']),
      R.over(
        R.lensPath(['health', 'contractDescription']),
        formatContractDescription
      ),
      R.assocPath(['health', 'contractNumbers'], contractNumbers)
    )({ health });

    SavePropositionMutation(propositionId, values, (ok) => {
      if (ok) {
        saveContractNumbers(contractNumbers);
        dispatch(propositionSetContractNumbersAreEdited(false));
      } else {
        saveContractNumbers([]);
      }
    });
  };
};

export const propositionPutUnhold = (updateStatus) => {
  return async (dispatch, getState) => {
    if (updateStatus) {
      let { propositionId } = getState().proposition.data;
      SavePropositionMutation(propositionId, { status: 'unhold' }, (ok) => {
        if (ok) {
          dispatch(propositionSetCurrentStep('PUT_UNHOLD_DONE_STEP'));
        }
      });
    } else {
      dispatch(propositionSetCurrentStep('PUT_UNHOLD_DONE_STEP'));
    }
  };
};

export const propositionConfirmContract = () => {
  return async (dispatch, getState) => {
    let { propositionId } = getState().proposition.data;
    SavePropositionMutation(propositionId, { status: 'confirmed' }, (ok) => {
      if (ok) {
        dispatch(propositionSetCurrentStep('CONTRACT_IS_CONFIRMED_STEP'));
      }
    });
  };
};

export const propositionSaveOverPricedRate = (overPricedRate) => {
  return async (dispatch) => {
    dispatch(propositionSetOverPricedRate(overPricedRate));
  };
};

export const propositionSaveClientEmail = (contactEmail) => {
  return async (dispatch, getState) => {
    let { propositionId, companyInformation } = getState().proposition.data;

    companyInformation = R.compose(
      R.modify(
        'escAdmin',
        R.reject(R.either(R.isNil, R.isEmpty)),
      ),
      R.assoc('contactEmail', contactEmail)
    )(companyInformation);

    dispatch(propositionSetCompanyInformation({ contactEmail }));
    SavePropositionMutation(propositionId, { companyInformation }, (ok, error) => {
      if (!ok) console.log(error);
    });
  };
};

export const propositionCloseContract = (reason) => {
  return async (dispatch, getState) => {
    let { propositionId } = getState().proposition.data;
    ClosePropositionMutation({ propositionId, reason }, (ok) => {
      if (ok) dispatch(propositionSetStatus('closed'));
    });
  };
};

export const propositionGenerateContractNumbers = (offerType) => {
  return async (dispatch, getState) => {
    const {
      data: { propositionId },
      generateContractNumbersLoading
    } = getState().proposition;

    if (!generateContractNumbersLoading) {
      dispatch(propositionSetGenerateContractNumbersLoading(true));
      dispatch(propositionSetGenerateContractNumbersError(false));

      GenerateContractNumbersMutation({ propositionId, offerType }, (ok, error, contractNumbers) => {
        dispatch(propositionSetGenerateContractNumbersLoading(false));

        if (ok && !error && !R.either(R.isNil, R.isEmpty)(contractNumbers)) {
          if (offerType === 'SANTE') {
            dispatch(propositionSetHealthContractNumbers(contractNumbers));
          } else {
            dispatch(propositionSetContractNumbers(contractNumbers));
          }

          dispatch(propositionSetCurrentStep('CONTRACT_NUMBERS_STEP'));
        } else {
          dispatch(propositionSetGenerateContractNumbersError(true));
        }
      });
    }
  };
};

export const propositionCloseAndDuplicateContract = () => {
  return async (dispatch, getState) => {
    const { propositionId } = getState().proposition.data;

    CloseAndDuplicateContractMutation({ contractId: propositionId }, (ok, error, newContractId) => {
      if (ok && !error && !R.isNil(newContractId)) {
        window.location = `/proposition?propositionId=${newContractId}&action=close_and_duplicate`;
      }
    });
  };
};
